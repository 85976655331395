import React from "react";
import {useDispatch} from 'react-redux';
import {logoutUser} from '../redux/actions';
import {useSelector} from 'react-redux';
import {State} from "../redux/reducer";
import {Link} from "react-router-dom";

interface NavigationComponent {
    page: string
}

export default function Navigation({page}: NavigationComponent) {
    const dispatch = useDispatch();
    const access_token = useSelector((state: State) => state.auth.accessToken);

    const currentPage = page;
    const noNavigation = ["/login", "/404", "/login/", "/404/"];
    const loginButton = ["/dashboard/", "/dashboard"];

    let visibility = "_simple";
    if (noNavigation.includes(currentPage)) {
        visibility = "_none";
    }

    if (loginButton.includes(currentPage) || currentPage.includes('/mitarbeiter/')) {
        visibility = "";
    }

    const handleLogout = () => {
        dispatch(logoutUser());
    }

    return (
        <div className={"navbar " + visibility}>
            <div className={"navbar-logo"}>
                <Link to={'/'}>
                    <img src="/images/logos/MATTH_OneShare_Marke.png"/>
                </Link>
            </div>
            <div className={"navbar-main"}>
                <nav aria-label={"breadcrumb"}>
                    <ol className={"breadcrumb"}>
                        <li className={"breadcrumb-item"}>

                        </li>
                    </ol>
                </nav>
                {access_token && (
                    <div className={"navbar-account"} onClick={handleLogout}>
                        <img src={'/images/heroicons-outline/login.svg'} alt={'Logout'} className={"navbar-icon"}/>
                        <div className={"navbar-user"}>
                            Abmelden
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

