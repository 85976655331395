import React from 'react';
import PropTypes from 'prop-types';
import "./styles/globals.scss";
import SideNavigation from "./components/SideNavigation";
import Navigation from "./components/Navigation";
import {store, persistor} from '../src/redux/store';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react'
import {useLocation, Switch, Router, Route} from 'react-router-dom';
import Index from "./pages/index";
import Dashboard from './pages/dashboard';
import Mitarbeiter from './pages/mitarbeiter/[id]';
import Login from './pages/login';
import history from './redux/history';

function App({Component, pageProps}: any) {
    const {pathname} = history.location;
    const hasSidebarPages = ["/dashboard/", '/dashboard'];

    let withSidebar = false;
    let pageLayout = '_default';

    if (hasSidebarPages.includes(pathname) || pathname.includes('/mitarbeiter/')) {
        withSidebar = true;
        pageLayout = '_columns';
    }

    return (
        <Router history={history}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <div className={"header " + pageLayout + "-navbar"}>
                        <Navigation page={pathname}/>
                    </div>
                    <div className={"__page-content " + pageLayout}>
                        {withSidebar &&
                        <SideNavigation/>
                        }
                        <div className={"__main-content"}>
                            <Switch>
                                <Route exact path="/login">
                                    <Login />
                                </Route>
                                <Route exact path="/dashboard">
                                    <Dashboard />
                                </Route>
                                <Route exact path="/mitarbeiter/:id">
                                    <Mitarbeiter />
                                </Route>
                                <Route path="/:hash" component={Index} />
                                <Route path="/" component={Index} />
                            </Switch>
                        </div>
                    </div>
                    <div className="footer">
                        <img src="/images/logos/wortmarke_matthaei.png"/>
                    </div>
                </PersistGate>
            </Provider>
        </Router>
    )
}

App.propTypes = {
    Component: PropTypes.any,
    pageProps: PropTypes.any
}

export default App;
