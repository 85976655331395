import React, {Component, useEffect, useState} from 'react';
import Select, {ActionMeta, ValueType} from 'react-select';
import {requestApiEndpoint} from "../../services/ApiService";

export function WorkplaceSelect({
                                        workplaces,
                                        handleWorkplaceChange,
                                        allowEmpty = false
                                    }: any): JSX.Element {
    const [selectOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        generateSelectFields(workplaces)
    }, [workplaces]);

    const generateSelectFields = (data: any) => {
        const selectOptions: any = [];

        if(allowEmpty) {
            selectOptions.push({
                "value": "", "label": "alle"
            })
        }

        data.forEach((element: string) => {
            selectOptions.push({
                "value": element, "label": element
            });
        });

        setSelectedOptions(selectOptions);
    }

    const colourStyles = {
        container: (styles: any) => ({...styles,
	        width: '100%', minWidth: '150px', marginLeft: '20px'}),
	    option: (styles: any, { isFocused}: any) => {
		    return {
			    ...styles,
			    backgroundColor: isFocused ? "#FA350F" : "null",
			    color: "#333333"
		    };
	    }
    };

    return (
        <Select
            styles={colourStyles}
            defaultValue={{value: "", label: "alle"}}
            onChange={handleWorkplaceChange}
            options={selectOptions}
            className={"__select"}
            classNamePrefix={"__select-inner"}
        />
    );
}
