import React from "react";
import {getAnschreibenPdf} from "../services/ApiService";

export default function GenerateAnschreiben({personalNumbers, callbackUpdateDashboard}: any): JSX.Element {
    async function handleGenerateAnschreiben() {
        const requestBody = new FormData();
        requestBody.append('personal_numbers', personalNumbers.join(','))
        await getAnschreibenPdf(requestBody, '/management/generate-anschreiben');
        callbackUpdateDashboard();
    }

    if(personalNumbers === undefined) {
        personalNumbers = [];
    }
    return <button
        disabled={personalNumbers.length === 0}
        style={{marginLeft: '20px'}}
        className={"btn btn-primary"}
        onClick={() => handleGenerateAnschreiben()}
    >
        Anschreiben herunterladen
    </button>
}
