import React, {useEffect, useState} from 'react';
import {store, persistor} from '../redux/store';
import {setUserCredentials} from '../redux/actions';
import {State} from '../redux/reducer';
import axios, {AxiosResponse} from "axios";
import history from '../redux/history';

export function postRegistrationForm(
    personalNumber: string,
    fullName: string,
    email: string,
    birthdate: string,
    streetAndHouseNo: string,
    zipAndPlace: string,
    shippingMethod: string,
    hash: string): Promise<any> {

    let formData = new FormData();
    formData.append("personalNumber", personalNumber);
    formData.append("fullName", fullName);
    formData.append("email", email);
    formData.append("birthdate", birthdate);
    formData.append("streetAndHouseNo", streetAndHouseNo);
    formData.append("zipAndPlace", zipAndPlace);
    formData.append("shippingMethod", shippingMethod);
    if (hash) {
        formData.append("registration_hash", hash);
    }

    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT + '/registration-form';
    return new Promise((resolve, reject) => {
        fetch(apiEndpoint, {
            method: "POST",
            body: formData
        })
            .then(response =>
		            response.json().then(
			            data => ({
				            data: data,
				            ok: response.ok,
				            status: response.status
			            })
		            )
            )
	        .then (response => {
		        if (response.ok) {
		            resolve(response.data);
		        } else {
		            reject(response.data.error)
		        }})
            .catch((error) => {
                reject(error);
            });
    })
}

export function postLogin(email: string, password: string): Promise<any> {
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT + "/oauth2/token";
    const requestBody = new FormData();
    requestBody.append('username', email);
    requestBody.append('password', password);

    return new Promise((resolve, reject) => {
        fetch(apiEndpoint, {
            method: "POST",
            body: requestBody
        })
            .then((response) => {
                if (response.ok) {
                    resolve(response.json());
                } else {
                    reject(response.json())
                }
            })
            .catch((error) => {
                reject(error);
            });
    })
}

export async function getAnschreibenPdf(requestBody: any, endpoint: string) {
    const state = store.getState();
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT + endpoint;

    await axios({
        url: apiEndpoint,
        method: 'POST',
        responseType: 'blob',
        headers: {
            Authorization: 'Bearer ' + state.auth.accessToken
        },
        data: requestBody
    })
        .then(response => {
            const file = new Blob([response.data], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            return true;
        })
        .catch(error => {
            return false;
        });
}

export function requestApiEndpoint(requestBody: any, endpoint: string): Promise<any> {
    const state = store.getState();
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT + endpoint;

    return new Promise((resolve, reject) => {
        fetch(apiEndpoint, {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + state.auth.accessToken
            },
            body: requestBody
        })
            .then((response) => {
                if (response.ok) {
                    resolve(response.json());
                } else {
                    if (response.status === 401) {
                        // if authentication fails refresh access tokens
                        refreshAccessToken()
                            // if refrsh succeeds autoamtically retry the previous api call
                            .then(success => {
                                requestApiEndpoint(requestBody, endpoint)
                                    .then(success => {
                                        resolve(success)
                                    })
                                    .catch(err => {
                                        reject(err)
                                    })
                            })
                            .catch(err => {
                                reject(err);
                            });
                    } else {
                        reject(response.json());
                    }
                }
            })
            .catch((error) => {
                reject(error);
            });
    })
}

export function refreshAccessToken() {
    const state = store.getState() as State;
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT + '/oauth2/refresh';
    const requestBody = new FormData();
    let refreshToken = state.auth.refreshToken ?? '';
    requestBody.append('refresh_token', refreshToken);

    return new Promise((resolve, reject) => {
        fetch(apiEndpoint, {
            method: "POST",
            body: requestBody
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return Promise.reject(response.statusText);
                }
            })
            .then((responseBody) => {
                const body = responseBody;
                store.dispatch(setUserCredentials(body.access_token, body.refresh_token));
                resolve(body);
            })
            .catch(async (error) => {
                history.push('/login');
                reject(error);
            });
    })
}

export function getDashboard(hash: string) {
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT + "/management/share-dashboard";

    fetch(apiEndpoint, {
        method: "POST",
        body: "registration_hash=" + hash
    })
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch(() => {
            console.error("fetch failed");
        });
}

export function getAnschreiben(hash: string) {
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT + "/management/generate-anschreiben";

    fetch(apiEndpoint, {
        method: "POST",
        body: "registration_hash=" + hash
    })
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch(() => {
            console.error("fetch failed");
        });
}

export function getParticipation(hash: string) {
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT + "/management/participation/get";

    fetch(apiEndpoint, {
        method: "POST",
        body: "registration_hash=" + hash
    })
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch(() => {
            console.error("fetch failed");
        });
}
