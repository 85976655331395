import React from "react";
import {Link, useLocation} from "react-router-dom";

export default function SideNavigation(): JSX.Element {
    const {pathname} = useLocation()

    const navigationPaths = {
        dashboard: "/dashboard",
        participations: "/participations",
        report: "/reports",
        downloads: "/downloads"
    }

    const activeClass: any = {
        dashboard: "",
        participations: "",
        report: "",
        downloads: "",
    };

    for (const [key, value] of Object.entries(navigationPaths)) {
        if (value === pathname) {
            activeClass[key] = ' active';
        }
    }


    return (
        <div className={"sidebar"}>
            <div className={"sidebar-list"}>
                <Link to={navigationPaths.dashboard} className={"__sidebar-list-item" + activeClass.dashboard}>
                    <p className={"sidebar-list-header"}>
                        Dashboard
                    </p>
                </Link>
                <Link to={'#'} className={"__sidebar-list-item" + activeClass.participations}>
	                <p className={"sidebar-list-header"}>
		                Beteiligungen
	                </p>
                </Link>
	            <Link to={'#'} className={"__sidebar-list-item" + activeClass.report}>
		            <p className={"sidebar-list-header"}>
			            Berichte
		            </p>
	            </Link>
	            <Link to={'#'} className={"__sidebar-list-item" + activeClass.downloads}>
		            <p className={"sidebar-list-header"}>
			            Downloads
		            </p>
                </Link>
            </div>
        </div>
    );
}
