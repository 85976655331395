import React, {useEffect, useState} from "react";
import {useLocation, useParams} from 'react-router-dom';
import Header from "../../components/Header";
import {requestApiEndpoint} from "../../services/ApiService";
import moment from "moment";
import { BillingPeriodSelect } from "../../components/Form/BillingPeriodSelect";

export default function Mitarbeiter(): JSX.Element {
    // @ts-ignore
    const {id = 0} = useParams();
    const [participation, setParticipation] = useState<any>({});
    const [userData, setUserData] = useState<any>({});
    const [editParticipation, setEditParticipation] = useState(false);
    const [transactionTime, setTransactionTime] = useState('');
    const [deposit, setDeposit] = useState('');
    const [billingPeriod, setBillingPeriod] = useState('once');

    useEffect(() => {
        if (id) {
            (async () => {
                getEmployeeData(id);
            })();
        }
    }, [id]);

    async function getEmployeeData(id: any) {
        const requestBody = new FormData();
        requestBody.append('personal_number', id);
        requestApiEndpoint(requestBody, '/management/participation/get')
            .then((data) => {
                setUserData(data);
                if (data.participation && data.participation.length > 0) {
                    setParticipation(data.participation[0]);
                    setBillingPeriod(data.participation[0].billing_period);
                    // @ts-ignore
                    setTransactionTime(moment(data.participation[0].transaction_datetime, "DD.MM.YYYY").format('YYYY-MM-DD'));
                    setDeposit(data.participation[0].deposit);
                }
            })
            .catch((err) => {
                console.log(err)
            });
    }

    const billingPeriodOptions: any = {
        'once': 'Einmalig',
        'monthly': 'Monatlich'
    };

    async function addEmployeeParticipation(participation: any, userData: any) {
        const userHasParticipation = (Object.keys(participation).length > 0);
        const saveParticipation = editParticipation || !userHasParticipation;
        setEditParticipation(!editParticipation);
        const formattedTransactionTime = moment(transactionTime, 'YYYY-MM-DD').format('DD.MM.YYYY');
        const requestBody = new FormData();
        requestBody.append('deposit', deposit);
        requestBody.append('transactionDatetime', transactionTime);
        requestBody.append('billingPeriod', billingPeriod);

        if (saveParticipation) {
            if ((Object.keys(participation).length > 0)) {
                // @ts-ignore
                requestBody.append('participation_id', parseInt(participation.id));
                requestApiEndpoint(requestBody, '/management/participation/update')
                    .then(() => {
                        setParticipation({
                            ...participation,
                            deposit: deposit,
                            transaction_datetime: formattedTransactionTime,
                            billing_period: billingPeriod,
                        });
                    })
                    .catch((err) => {
                        console.log(err)
                    });
            } else {
                // @ts-ignore
                requestBody.append('personal_number', parseInt(userData.personal_number));
                requestApiEndpoint(requestBody, '/management/participation/create')
                    .then((data) => {
                        setParticipation({
                            id: data.id,
                            deposit: deposit,
                            transaction_datetime: formattedTransactionTime,
                            billing_period: billingPeriod,
                        });
                        setEditParticipation(false);
                    })
                    .catch((err) => {
                        console.log(err)
                    });
            }
        }
    }

    const userHasParticipation = (Object.keys(participation).length > 0);
    return (
        <div className={"container"}>
            <div className={"row"}>
                <div className={"col-12"}>
                    <Header title={"Beteiligung 2021"} pretitle={userData.first_name + ' ' + userData.last_name}/>
                </div>
                <div className={"col-12 col-md-9"}>
                    <div className="card card-shadow _card-border-none">
                        <div className="card-header">
                            <h4 className="card-header-title">
                                Kontaktdaten
                            </h4>
                            <a className="configurator-link" href="#company-configurator">
                                ändern
                            </a>
                        </div>
                        <div className="card-body">
                            <div className="card-text card-text-list">
                                <div className="card-info-details">
                                    <div className={"__card-list"}>
                                        <div className={"__card-list-name"}>Name, Vorname</div>
                                        <div
                                            className={"__card-list-value"}>{userData.last_name}, {userData.first_name}</div>
                                    </div>
                                    <div className={"__card-list"}>
                                        <div className={"__card-list-name"}>Personalnummer</div>
                                        <div className={"__card-list-value"}>{userData.personal_number}</div>
                                    </div>
                                    <div className={"__card-list"}>
                                        <div className={"__card-list-name"}>E-Mail-Adresse</div>
                                        <div className={"__card-list-value"}>{userData.email}</div>
                                    </div>
                                    <div className={"__card-list"}>
                                        <div className={"__card-list-name"}>Lohnart</div>
                                        <div className={"__card-list-value"}>{userData.salary_kind}</div>
                                    </div>
                                    <div className={"__card-list"}>
                                        <div className={"__card-list-name"}>Standort</div>
                                        <div className={"__card-list-value"}>{userData.workplace_location}</div>
                                    </div>
                                    <div className={"__card-list"}>
                                        <div className={"__card-list-name"}>Straße, Hausnummer</div>
                                        <div className={"__card-list-value"}>{userData.street_and_house_no}</div>
                                    </div>
                                    <div className={"__card-list"}>
                                        <div className={"__card-list-name"}>Ort, PLZ</div>
                                        <div className={"__card-list-value"}>{userData.place}, {userData.zip}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card card-shadow _card-border-none">
                        <div className="card-header">
                            <h4 className="card-header-title">
                                Beteiligung
                            </h4>
                            <div className="configurator-link"
                                 onClick={() => addEmployeeParticipation(participation, userData)}>
                                {(editParticipation || !userHasParticipation)
                                    ? 'speichern'
                                    : 'ändern'
                                }
                            </div>
                        </div>
                        {(editParticipation || !userHasParticipation) && (
                            <div className="card-body">
                                <div className="card-text card-text-list">
                                    <div className="card-info-details">
                                        <div className={"__card-list"}>
                                            <div className={"__card-list-name"}>Startdatum</div>
                                            <div className={"__card-list-value"}>
                                                <input
                                                    type={"date"}
                                                    name={"time"}
                                                    value={moment(transactionTime).format('YYYY-MM-DD')}
                                                    onChange={(e) => setTransactionTime(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className={"__card-list"}>
                                            <div className={"__card-list-name"}>Beteiligungssumme</div>
                                            <div className={"__card-list-value"}>
                                                <input type={"text"} name={"deposit"}
                                                       value={deposit}
                                                       onChange={(e) => setDeposit(e.target.value)}
                                                />&nbsp;Euro
                                            </div>
                                        </div>
                                        <div className={"__card-list"}>
                                            <div className={"__card-list-name"}>Beteiligungsart</div>
                                            <div className={"__card-list-value"}>
                                                <BillingPeriodSelect participation_state={billingPeriod} handleParticipationStateChange={setBillingPeriod} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {userHasParticipation && !editParticipation && (
                            <div className="card-body">
                                <div className="card-text card-text-list">
                                    <div className="card-info-details">
                                        <div className={"__card-list"}>
                                            <div className={"__card-list-name"}>Startdatum</div>
                                            <div className={"__card-list-value"}>
                                                {participation.transaction_datetime}
                                            </div>
                                        </div>
                                        <div className={"__card-list"}>
                                            <div className={"__card-list-name"}>Beteiligungssumme</div>
                                            <div
                                                className={"__card-list-value"}>{participation.deposit} Euro
                                            </div>
                                        </div>
                                        <div className={"__card-list"}>
                                            <div className={"__card-list-name"}>Beteiligungsart</div>
                                            <div
                                                className={"__card-list-value"}>{billingPeriodOptions[participation.billing_period]}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className={"col-12 col-md-3"}>

                </div>
            </div>
        </div>
    )
}
