import React, {useEffect, useRef, useState} from "react";
import {postRegistrationForm} from "../services/ApiService";
import {Link} from "react-router-dom";
import Select from 'react-select';
import Modal from "react-modal";


export default function Index(props: any): JSX.Element {
    // @ts-ignore
    const [state, setState] = useState({
        personalNumber: '',
        fullName: '',
        email: '',
        birthdateDay: null,
        birthdateMonth: null,
        birthdateYear: null,
        streetAndHouseNo: '',
        zipAndPlace: '',
        shippingMethod: '',
        hash: '',
        error: {}
    });

	const [birthdayOptions, setBirthdayOptions] = useState([]);
	const [monthOptions, setMonthOptions] = useState([]);
	const [yearOptions, setYearOptions] = useState([]);
	const [successModalIsOpen, showRegistrationSuccessModal] = useState(false);


	const handleParam = (e: React.FormEvent<HTMLTextAreaElement>) => {
        const {name, value} = e.target as HTMLTextAreaElement;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSelect = (value: any, event: any) => {
	    const name = event.name;
	    setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        setRegistrationValues();
    }, []);

    function setRegistrationValues() {
    	let birtdayValues: any = [];
    	for (let i:number = 1; i <= 31; i++) {
    		birtdayValues.push({
			    value: i, label: i
		    });
	    }
    	setBirthdayOptions(birtdayValues);

    	let monthValues: any = [];
    	for (let i:number = 0; i <= 11; i++) {
    		monthValues.push({
			    value: i+1, label: new Date(2000, i).toLocaleString('de-DE', {month: 'long'})
		    });
	    }
    	setMonthOptions(monthValues);

    	const currentYear:number = new Date().getFullYear();
    	let yearValues: any = [];
    	for (let i:number = currentYear; i >= currentYear - 100; i--) {
    		yearValues.push({
			    value: i, label: i
		    });
	    }
    	setYearOptions(yearValues);
    }

    const setError = (error: any) => {
        setState(prevState => ({
            ...prevState,
            error: error
        }));
    };


	const selectBirthdayDayStyles = {
		container: (provided: any) => ({...provided, color: 'black', flexGrow: 4, zIndex: 2}),
		option: (styles: any, { isFocused }: any) => {
			return {
				...styles,
				backgroundColor: isFocused ? "#FA350F" : "null",
				color: "#333333"
			};
		}
	};
	const selectBirthdayMonthStyles = {
		container: (provided: any) => ({...provided, color: 'black', flexGrow: 4, marginLeft: '5px', zIndex: 2}),
		option: (styles: any, { isFocused }: any) => {
			return {
				...styles,
				backgroundColor: isFocused ? "#FA350F" : "null",
				color: "#333333"
			};
		}
	};
	const selectBirthdayYearStyles = {
		container: (provided: any) => ({...provided, color: 'black', flexGrow: 6, marginLeft: '5px', zIndex: 2}),
		option: (styles: any, { isFocused }: any) => {
			return {
				...styles,
				backgroundColor: isFocused ? "#FA350F" : "null",
				color: "#333333"
			};
		}
	};

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setError({});

	    let birthdayValue: string = '';
	    if (state.birthdateDay && state.birthdateMonth && state.birthdateYear) {
		    // @ts-ignore
		    birthdayValue = state.birthdateDay.value + '.' + state.birthdateMonth.value + '.' + state.birthdateYear.value;
	    }

	    postRegistrationForm(
		    state.personalNumber,
		    state.fullName,
		    state.email,
		    birthdayValue,
            state.streetAndHouseNo,
            state.zipAndPlace,
            state.shippingMethod,
            state.hash)
            .then(() => {
	            showRegistrationSuccessModal(true);
            })
		    .catch((error) => {
			    if (typeof error !== 'object') {
				    error = {generic: error};
			    }
			    setError(error);
            });
    };

	function toggleRegistrationSuccessModal() {
		showRegistrationSuccessModal(!successModalIsOpen);
	}
	function handleSuccessModalClose() {
		setState(prevState => ({
			...prevState,
			personalNumber: '',
			fullName: '',
			email: '',
			birthdateDay: null,
			birthdateMonth: null,
			birthdateYear: null,
			streetAndHouseNo: '',
			zipAndPlace: '',
			shippingMethod: '',
		}));
	}

	const scrollToRef = useRef(null);
	// @ts-ignore
	const executeScroll = () => scrollToRef.current.scrollIntoView({behavior: 'smooth'});

    return (
        <div className={"container"}>
            <div className={"__content _hero"}>
                <div className={"__hero-wrapper"}>
                    <div className={"__image"}>
                        <img src="/images/matthaei-oneshare-10720118282@0,5x_800px.jpg" alt=""/>
                    </div>
                    <div className={"__text"}>
                        <div className={"__header"}>
                            <h1 className={"__headline _large"}>Dein Anteil am Erfolg</h1>
                        </div>
                        <div className={"bodytext"}>
                            Die Matthäi Mitarbeiterbeteiligung macht den Erfolg der Gruppe zur persönlichen Chance.
                        </div>
                        <Link to={"/"}>
                            <button className={"btn btn-primary _icon"} onClick={executeScroll}>
                                <span>Jetzt mitmachen</span>
                                <div className={"__icon"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" stroke="#ffffff"/>
                                    </svg>
                                </div>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className={"__content"}>
                <div className={"__intro"}>
                    <div className={"__header"}>
                        <h2 className={"__headline"}>Mitarbeiter-Beteilungs-Programm</h2>
                    </div>
                    <div className={"__teaser"}>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                            invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam
                            et justo duo dolores et ea rebum.
                        </p>
                        <Link to="/" className={"__link _icon d-none d-sm-flex"} onClick={executeScroll}>
                            <div className={"__icon"}>
                                <img src="/images/heroicons-outline/arrow-right.svg" alt=""/>
                            </div>
                            <span>Jetzt Vertragsunterlagen anfordern</span>
                        </Link>
                    </div>
                </div>
                <div className={"__quick-info _content-padding-lg"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-4"}>
                            <div className={"__quick-info-wrapper"}>
                                <div className={"__icon"}>
                                    <img src="../images/logos/oneshare_cash.svg" alt=""/>
                                </div>
                                <div className={"__headline"}>
                                    Bis zu 360€ Zulage
                                </div>
                                <div className={"bodytext"}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus venenatis ac elit ut
                                    nisl
                                </div>
                            </div>
                        </div>
                        <div className={"col-12 col-md-4"}>
                            <div className={"__quick-info-wrapper"}>
                                <div className={"__icon"}>
                                    <img src="../images/logos/oneshare_7percent.svg" alt=""/>
                                </div>
                                <div className={"__headline"}>
                                    Bis zu 7% Verzinsung
                                </div>
                                <div className={"bodytext"}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus venenatis ac elit ut
                                    nisl
                                </div>
                            </div>
                        </div>
                        <div className={"col-12 col-md-4"}>
                            <div className={"__quick-info-wrapper"}>
                                <div className={"__icon"}>
                                    <img src="../images/logos/oneshare_handshake.svg" alt=""/>
                                </div>
                                <div className={"__headline"}>
                                    Einfach & transparent
                                </div>
                                <div className={"bodytext"}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus venenatis ac elit ut
                                    nisl
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"__register-wrapper"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6 col-lg-7"}>
                            <div className={"__info-wrapper"}>
                                <div className={"__header"}>
                                    <h3 className={"__headline"}>
                                        Gemeinsam erfolgreich -- jetzt und in Zukunft!
                                    </h3>
                                </div>
                                <div className={"__teaser"}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean pellentesque amet
                                    vel eu
                                    molestie orci consectetur.
                                </div>
                                <div className={"bodytext"}>
                                    <p>Die Matthäi-Gruppe möchte den Mitarbeiterinnen und Mitarbeitern (im Weiteren:
                                        Mitarbeiter) die Möglichkeit eröffnen, sich am Kapital und somit auch am
                                        finanziellen Erfolg der Unternehmensgruppe zu beteiligen. Es ist beabsichtigt,
                                        den
                                        Mitarbeitern in den kommenden Jahren jeweils ein Beteiligungsangebot für eine
                                        finanzielle Einlage zu machen, die durch eine Zulage des Arbeitgebers spürbar
                                        aufgestockt wird.</p>
                                    <p>Die Matthäi-Gruppe möchte mit diesem Programm das Engagement und das
                                        unternehmerische
                                        Denken der Mitarbeiter fördern, ihre Bindung an die Gruppe stärken und die
                                        Performance der Matthäi-Gruppe weiter verbessern.</p>
                                    <p>Die Mitarbeiterbeteiligung wertet die Stellung der teilnehmenden Mitarbeiter auf
                                        und
                                        trägt zur Erhöhung der Arbeitszufriedenheit bei. Sie ist eine lukrative – aber
                                        auch
                                        mit Risiken verbundene – Form der Geldanlage, ein Mittel zur nachhaltigen
                                        Unterstützung der Vermögensbildung und Ausdruck der Wertschätzung gegenüber
                                        allen
                                        Mitarbeitern.</p>
                                    <p>Zu diesem Zweck können sich die Mitarbeiter der Unternehmen der Matthäi-Gruppe
                                        als
                                        stille Gesellschafter an der Matthäi Mitarbeiter Beteiligungs Gesellschaft
                                        m.b.H.
                                        (MMBG) beteiligen. Diese wird sich wiederum umgehend, in gleicher Höhe als
                                        stiller
                                        Gesellschafter an der Rudolf Matthäi Industrie- und Verwaltungsgesellschaft
                                        m.b.H. &
                                        Co. KG, Verden (Aller) beteiligen („indirekte stille Beteiligung“).</p>
                                </div>
                            </div>
                            <div className={"__contact-partner"}>
                                <div className={"__header"}>
                                    <h3 className={"__headline"}>
                                        Ansprechpartner
                                    </h3>
                                </div>
                                <div className={"__image-text-wrapper"}>
                                    <div className={"__image"}>
                                        <img src="../images/oneshare_contact-person.png" alt=""/>
                                    </div>
                                    <div className={"__text"}>
                                        <p>Nicolas Ilari Brunken<br />
                                            Tel.: 01234567891011<br />
                                            Mustermann@Matthäi.de<br />
                                            Mo - Fr&nbsp;&nbsp;8.00 - 17.00</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"col-12 col-md-6 col-lg-5"}>
                            <div ref={scrollToRef} className={"__register"}>
                                <div className={"__header"}>
                                    <h4 className={"__headline"}>Jetzt mitmachen!</h4>
                                </div>
                                {/*todo: move to component*/}
                                <form id={"register-form"} className={"form register-form"}
                                      onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleSubmit(e)}>
                                    <div className={"form-group"}>
                                        <label className={"form-label"} htmlFor={"personalNumber"}>
                                            Personalnummer *
                                        </label>
                                        <input className={"form-control"}
                                               type="text"
                                               name={"personalNumber"}
                                               value={state.personalNumber}
                                               onChange={(e: any) => handleParam(e)}
                                        />
                                        <div className="invalid-feedback"
                                             style={{display: ('personalNumber' in state.error ? 'block' : 'none')}}>
                                            Die eingegebene Personalnummer ist falsch.
                                        </div>
                                    </div>

                                    <div className={"form-group"}>
                                        <label className={"form-label"} htmlFor={"fullName"}>
                                            Vor- und Nachname *
                                        </label>
                                        <input className={"form-control"}
                                               type="text"
                                               name={"fullName"}
                                               value={state.fullName}
                                               onChange={(e: any) => handleParam(e)}
                                        />
                                        <div className="invalid-feedback"
                                             style={{display: ('fullName' in state.error ? 'block' : 'none')}}>
                                            Der eingegebene Vor- oder Nachname ist falsch.
                                        </div>
                                    </div>

                                    <div className={"form-group"}>
                                        <label className={"form-label"} htmlFor={"email"}>
                                            E-Mail-Adresse *
                                        </label>
                                        <input className={"form-control"}
                                               type="mail"
                                               name={"email"}
                                               value={state.email}
                                               onChange={(e: any) => handleParam(e)}
                                        />
                                        <div className="invalid-feedback"
                                             style={{display: ('email' in state.error ? 'block' : 'none')}}>
                                            Die eingegebene E-Mail-Adresse ist falsch.
                                        </div>
                                    </div>

                                    <div className={"form-group _birthdate"}>
                                        <fieldset>
                                            <label className={"form-label"} htmlFor={"birthdate"}>
                                                Geburtsdatum *
                                            </label>
                                            <span className={"__input-wrapper"}>
	                                            <Select
		                                            placeholder={"TT"}
		                                            name={"birthdateDay"}
		                                            onChange={handleSelect}
		                                            styles={selectBirthdayDayStyles}
		                                            options={birthdayOptions}
		                                            value={state.birthdateDay}
		                                            classNamePrefix="_select"
	                                            />
	                                            <Select
		                                            placeholder={"MM"}
		                                            name={"birthdateMonth"}
		                                            onChange={handleSelect}
		                                            styles={selectBirthdayMonthStyles}
		                                            options={monthOptions}
		                                            value={state.birthdateMonth}
		                                            classNamePrefix="_select"
	                                            />
	                                            <Select
		                                            placeholder={"JJJJ"}
		                                            onChange={handleSelect}
		                                            name={"birthdateYear"}
		                                            styles={selectBirthdayYearStyles}
		                                            options={yearOptions}
		                                            value={state.birthdateYear}
		                                            classNamePrefix="_select"
	                                            />
                                            </span>
                                            <div className="invalid-feedback"
                                                 style={{display: ('birthdate' in state.error ? 'block' : 'none')}}>
                                                Das eingegebene Geburtsdatum ist falsch.
                                            </div>
                                        </fieldset>
                                    </div>

                                    <div className={"form-group"}>
                                        <label className={"form-label"} htmlFor={"streetAndHouseNo"}>
                                            Straße, Nr. *
                                        </label>
                                        <input className={"form-control"}
                                               type="text"
                                               name={"streetAndHouseNo"}
                                               value={state.streetAndHouseNo}
                                               onChange={(e: any) => handleParam(e)}
                                        />
                                        <div className="invalid-feedback"
                                             style={{display: ('streetAndHouseNo' in state.error ? 'block' : 'none')}}>
                                            Die eingegebene Anschrift ist falsch.
                                        </div>
                                    </div>

                                    <div className={"form-group"}>
                                        <label className={"form-label"} htmlFor={"zipAndPlace"}>
                                            PLZ, Ort *
                                        </label>
                                        <input className={"form-control"}
                                               type="text"
                                               name={"zipAndPlace"}
                                               value={state.zipAndPlace}
                                               onChange={(e: any) => handleParam(e)}
                                        />
                                        <div className="invalid-feedback"
                                             style={{display: ('zipAndPlace' in state.error ? 'block' : 'none')}}>
                                            Die eingegebene Postleitzahl ist falsch.
                                        </div>
                                    </div>

                                    <div className={"form-group"}>
                                        <fieldset>
                                            <label className={"__radio-label _white"}>
                                                Versandart wählen *
                                            </label>
                                            <div className="custom-control custom-radio"
                                                 onChange={(e: any) => handleParam(e)}>
                                                <div className="radio-circle _black">
                                                    <input type="radio" className={"custom-control-input"}
                                                           name="shippingMethod"
                                                           id="shippingMethod"
                                                           value="post"
                                                           checked={state.shippingMethod === "post"}
                                                    >
                                                    </input>
                                                    <label className={"custom-control-label"} htmlFor="shippingMethod">
                                                        per Post
                                                    </label>
                                                </div>
                                                <div className="radio-circle _black">
                                                    <input type="radio" className={"custom-control-input"}
                                                           name="shippingMethod"
                                                           id="shippingMethod2"
                                                           value="email"
                                                           checked={state.shippingMethod === "email"}
                                                    >
                                                    </input>
                                                    <label className={"custom-control-label"} htmlFor="shippingMethod2">
                                                        per E-Mail
                                                    </label>
                                                </div>
                                            </div>
                                        </fieldset>
	                                    <div className="invalid-feedback"
	                                         style={{display: ('shippingMethod' in state.error ? 'block' : 'none')}}>
		                                    Die eingegebene Versandmethode ist falsch.
	                                    </div>

                                    </div>

                                    <div className="__submit">
                                        <button id="register-submit" type="submit" className="btn btn-white _btn-bold"
                                                value="submit">Jetzt Unterlagen anfordern
                                        </button>
	                                    <div className="invalid-feedback"
	                                         style={{display: ('generic' in state.error ? 'block' : 'none')}}>
		                                    {('generic' in state.error) &&
		                                    // @ts-ignore
		                                    state.error.generic
		                                    }
	                                    </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
	        <Modal
		        isOpen={successModalIsOpen}
		        onRequestClose={toggleRegistrationSuccessModal}
		        contentLabel="My dialog"
		        className="successModal"
		        onAfterClose={handleSuccessModalClose}
		        style={{
		        	overlay: {zIndex: 999, backgroundColor: '#00000054'},
			        content: {
		        		position: 'absolute' as 'absolute',
				        top: '50%',
				        left: '50%',
				        transform: 'translate(-50%, -50%)',
				        background: 'white',
				        padding: '50px 100px',
				        textAlign: 'center'
		        	}
		        }}
	        >
		        <p><img src="/images/check-circle-thin-green.svg" alt=""/></p>
		        <p>Vielen Dank.</p>
		        <p>Sie erhalten in den nächsten <strong>3-5 Werktagen</strong><br/> ihre Vertragsunterlagen per {(state.shippingMethod === "post") ? 'Post' : "E-Mail"}.</p>

		        <button onClick={toggleRegistrationSuccessModal} style={{background: 'transparent', position: "absolute" as "absolute", top: 10, right: 10, border: 'none'}}>
			        <img src="/images/heroicons-outline/x.svg" style={{height: '20px'}} alt=""/>
		        </button>
	        </Modal>

        </div>
    );
}
