import React, {useEffect, useMemo, useState} from "react";
import DataTable from 'react-data-table-component';
import Header from "../components/Header";
import {requestApiEndpoint} from '../services/ApiService';
import DoubleRowCell from "../components/Form/DoubleRowCell";
import DateCell from "../components/Form/DateCell";
import {ParticipationSelect} from "../components/Form/ParticipationSelect";
import {useDispatch} from 'react-redux';
import {updateStatusForUser} from "../redux/actions";
import GenerateAnschreiben from "../components/GenerateAnschreiben";
import {useRef} from "react";
import {useHistory} from "react-router-dom";
import Select from 'react-select';
import {WorkplaceSelect} from "../components/Form/WorkplaceSelect";


const FilterComponent = ({filterText, onFilter}: any) => (
    <div className={"__custom-subheader-component"}>
        <div className={"__select-filter"}>

        </div>
        <div className={"__search-field"}>
            <input id="search"
                   className="form-control _search"
                   type="text"
                   placeholder="Suchen"
                   aria-label="Search Input"
                   value={filterText}
                   onChange={onFilter}/>
            <div className={"icon _search"}>
                <img src="/images/heroicons-outline/search.svg"/>
            </div>
            {/*<button type="button" className="__search-button" onClick={onClear}>X</button>*/}
        </div>
    </div>
);

export default function Dashboard(): JSX.Element {
    const history = useHistory();
    let inputRef = useRef(null);
    const dispatch = useDispatch();

    const [datatable, setDatatable] = useState([]);
    const [filteredDatatable, setFilteredDatatable] = useState([]);
    const [selectedPersonalNumbers, setSelectedPersonalNumbers] = useState([]);
    const [clearSelectedRows, setClearSelectedRows] = useState(false);
    const [fileUploadStatus, setFileUploadStatus] = useState('+ Daten importieren');
    const [selectOptions, setSelectedOptions] = useState([]);
    const [workplaceLocations, setWorkplaceLocations] = useState([]);
    const [participationStates, setParticipationStates] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [updatingDatatable, setUpdatingDatable] = React.useState(false);

    const [filterText, setFilterText] = React.useState('');
    const [filterWorkplace, setFilterWorkplace] = React.useState('');
    const [filterStatus, setFilterStatus] = React.useState('');

    const handleFileInput = (e: any) => {
        const file = e.target.files[0];
        if (file.type !== 'text/csv') {
            setFileUploadStatus('Ungültige Datei. Die ausgewählte Datei ist keine CSV.')
            setTimeout(() => {
                setFileUploadStatus('+ Daten importieren')
            }, 3000);
            return;
        }

        setFileUploadStatus('Datei wird hochgeladen...');
        const requestBody = new FormData();
        requestBody.append('employees', file);
        requestApiEndpoint(requestBody, '/management/employee/import')
            .then(data => {
                setFileUploadStatus(data.message);
                setTimeout(() => {
                    setFileUploadStatus('+ Daten importieren')
                }, 3000);
            })
            .catch(err => {
                setFileUploadStatus('Datei konnte nicht hochgeladen werden.');
                setTimeout(() => {
                    setFileUploadStatus('+ Daten importieren')
                }, 3000);
            })
    }

    // const checkbox = <Checkbox />;
    const getData = async () => {
        setUpdatingDatable(true);
        return requestApiEndpoint(null, '/management/share-dashboard')
            .then((response: any) => {
                setWorkplaceLocations(response.dashboard.filter.workplace_location)
                setParticipationStates(response.dashboard.filter.status)
                generateSelectFields(response.dashboard.filter.status);
                const dashboard = generateDashboard(response.dashboard.data);
                setClearSelectedRows(!clearSelectedRows);
                setUpdatingDatable(false);
                return dashboard;
            }).catch(err => {
                console.log(err);
            });
    }

    useEffect(() => {
        getData()
    }, []);

    const generateSelectFields = (data: any) => {
        const selectOptions: any = [];

        data.forEach((element: string) => {
            selectOptions.push({
                "value": element, "label": element
            });
        });
        setSelectedOptions(selectOptions);
    }

    const handleRowClicked = (row: any) => {
        history.push('/mitarbeiter/' + row.personal_number);
    }

    // todo: format
    const generateDashboard = (data: any) => {
        const formattedDashboard: any = [];
        data.forEach((user: any) => {
            const participation_deposit = user.participation[0] !== undefined ? user.participation[0].deposit + ' €' : '';
            const participation_date = user.participation[0] !== undefined ? user.participation[0].transaction_datetime : '';
            let participation_billing = user.participation[0] !== undefined ? user.participation[0].billing_period : '';

            // todo: add i18n use api response as translation key
            if (participation_billing !== '') {
                if (participation_billing == 'once') {
                    participation_billing = 'einmalig';
                } else {
                    participation_billing = 'monatlich';
                }
            }

            formattedDashboard.push({
                'name': user.first_name + ' ' + user.last_name,
                'personal_number': user.personal_number,
                'salary_kind': user.salary_kind,
                'workplace_location': user.workplace_location,
                'participation_state': user.participation_state,
                'participation_deposit': participation_deposit,
                'participation_date': user.first_entry,
                'participation_billing': participation_billing
            })
        });
        setDatatable(formattedDashboard);
        setFilteredDatatable(formattedDashboard);
        return formattedDashboard;
    }

    function convertArrayOfObjectsToCSV(array: any[]) {
        let result: any;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(datatable[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach((item: any) => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;
                result += item[key];

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }

    function downloadCSV(array: any[]) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;

        const filename = 'export.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    async function handleParticipationStateChange(status: any, personalNumber: any) {
        setUpdatingDatable(true);
        await dispatch(updateStatusForUser(status.value, personalNumber));
        const data = await getData();
        filterElements({data});
    }

    function onSelectedRowChange({selectedRows}: any) {
        const selectedPersonalNumbers: any = [];
        selectedRows.forEach((row: any) => {
            selectedPersonalNumbers.push(row.personal_number);
        });

        setSelectedPersonalNumbers(selectedPersonalNumbers);
    }

    const Export = ({onExport}: any) => (
        <button className={"btn btn-primary"}
                onClick={(e: any) => onExport((e.target as HTMLInputElement).value)}>Export</button>
    );

    const columns = [
        {
            name: 'Name',
            selector: 'name', // todo
            sortable: true,
            minWidth: '240px',
            cell: (row: any) => <DoubleRowCell firstRow={row.name}
                                               secondRow={row.personal_number}
                                               redirectToEmployeeDetailPage={() => handleRowClicked(row)}/>
        },
        {
            name: 'Personalnummer',
            selector: 'personal_number',
            omit: true,
            width: '200px'
        },
        {
            name: 'Lohnart',
            selector: 'salary_kind',
            sortable: true,
            width: '100px',
            cell: (row: any) => <DoubleRowCell firstRow={row.salary_kind}
                                               secondRow={''}
                                               redirectToEmployeeDetailPage={() => handleRowClicked(row)}/>
        },
        {
            name: 'Beteiligung',
            selector: 'participation_deposit',
            sortable: true,
            cell: (row: any) => <DoubleRowCell
                firstRow={row.participation_deposit} secondRow={row.participation_billing}
                redirectToEmployeeDetailPage={() => handleRowClicked(row)}/>
        },
        {
            name: 'Standort',
            selector: 'workplace_location', // todo
            minWidth: '300px',
            sortable: true,
            cell: (row: any) => <DoubleRowCell firstRow={row.workplace_location}
                                               secondRow={''}
                                               redirectToEmployeeDetailPage={() => handleRowClicked(row)}/>
        },
        {
            name: 'Datum',
            selector: 'first_entry', // todo
            sortable: true,
            width: '200px',
            cell: (row: any) => <DateCell firstRow={row.participation_date}
                                          redirectToEmployeeDetailPage={() => handleRowClicked(row)}/>
        },
        {
            name: <ParticipationSelect
                participation_state={""}
                participationStates={participationStates}
                handleParticipationStateChange={(status: any) => {
                    handleParticipationStateChange(status, selectedPersonalNumbers.join(','))
                }}
                allowEmpty={false}
            />,
            selector: 'participation_state',
            sortable: false,
            cell: (row: any) => <ParticipationSelect participation_state={row.participation_state}
                                                     participationStates={participationStates}
                                                     personalNumber={row.personal_number}
                                                     handleParticipationStateChange={handleParticipationStateChange}/>
        },
    ]

    const filterElements = ({
                                text = filterText,
                                status = filterStatus,
                                workplace = filterWorkplace,
                                data = datatable
                            }) => {
        const nameList = filterItemsByName(text, data);
        const statusList = filterItemsByStatus(status, nameList);
        const workplaceList = filterByWorkplace(workplace, statusList);
        setFilteredDatatable(workplaceList);
    }

    let filterItemsByName = (name: any, rawDatatable = filteredDatatable) => {
        const updatedDatatable = rawDatatable.filter((item: any) => {
            return item.name && item.name.toLowerCase().includes(name.toLowerCase())
        })

        return updatedDatatable;
    };

    let filterItemsByStatus = (status: any, rawDatatable = filteredDatatable) => {
        const updatedDatatable = rawDatatable.filter((item: any) => {
            return item.participation_state && item.participation_state.toLowerCase().includes(status.toLowerCase())
        });

        return updatedDatatable;
    }

    let filterByWorkplace = (workplace: any, rawDatatable = filteredDatatable) => {
        const updatedDatatable = rawDatatable.filter((item: any) => {
            return item.workplace_location && item.workplace_location.toLowerCase().includes(workplace.toLowerCase())
        });

        return updatedDatatable;
    }

    const paginationSettings = {
        rowsPerPageText: 'Zeilen pro Seite:'
    }

    const SubHeaderComponentMemo = useMemo(() => {
        return <FilterComponent
            onFilter={(e: any) => { const text = e.target.value; setFilterText(text); filterElements({text: text}); }}
            filterText={filterText}
        />;
    }, [filterText]);

    const filterComponente = <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
        <div className={'dropdown-filter'} style={{display: 'flex'}}>
            <WorkplaceSelect
                workplaces={workplaceLocations}
                allowEmpty={true}
                handleWorkplaceChange={(workplace: any) => {setFilterWorkplace(workplace.value); filterElements({workplace: workplace.value}); }}
            />
            <ParticipationSelect
                participation_state={"alle"}
                participationStates={participationStates}
                handleParticipationStateChange={(status: any) => {setFilterStatus(status.value); filterElements({status: status.value}); }}
                allowEmpty={true}
            />
        </div>

        {SubHeaderComponentMemo}
    </div>

    return (
        <div className={"container"}>
            <Header title={"Beteiligung 2021"}/>
            <div className="list-wrapper">
                <div className="list-table table-responsive">
                    <div className={"datatable-loader"} style={{display: (updatingDatatable ? 'flex' : 'none')}}>
                        <span>
                            Tabelle wird aktualisiert ...
                        </span>
                    </div>
                    <DataTable
                        // @ts-ignore
                        columns={columns}
                        data={filteredDatatable}
                        selectableRows={true}
                        highlightOnHover={true}
                        pagination={true}
                        paginationPerPage={20}
                        paginationRowsPerPageOptions={[10, 25, 50]}
                        clearSelectedRows={clearSelectedRows}
                        paginationComponentOptions={paginationSettings}
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        noHeader
                        subHeader
                        subHeaderComponent={filterComponente}
                        // selectableRowsComponent={Checkbox}
                        // sortFunction={(column, sortDirection) => handleSort(column, "asc")}
                        onRowClicked={(row) => handleRowClicked(row)}
                        onSelectedRowsChange={onSelectedRowChange}
                    />
                    <div className={'export-wrapper mt-4'} style={{float: 'right'}}>
                        <Export onExport={() => downloadCSV(datatable)}/>
                        <GenerateAnschreiben personalNumbers={selectedPersonalNumbers}
                                             callbackUpdateDashboard={getData}
                        />
                    </div>
                </div>
                <div className={'export-wrapper mt-4'}>
                    <input
                        type="file"
                        id="customFile"
                        onChange={handleFileInput}
                        // @ts-ignore
                        ref={input => inputRef = input}
                        style={{display: 'none'}}
                    />
                    <div
                        className="csvUploadArea"
                        // @ts-ignore
                        onClick={() => inputRef.click()}
                        style={{
                            width: '100%',
                            height: '65px',
                            border: '1px dashed black',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer'
                        }}>{fileUploadStatus}</div>
                </div>
            </div>
        </div>
    );
}
