export function BillingPeriodSelect({participation_state, handleParticipationStateChange}: any): JSX.Element {
    const selectOptions: any = {
        "once": "Einmalig",
        "monthly": "Monatlich"
    }

    return (
        <div>
            <div>
                <select
                    id="participation-select"
                    name="participation"
                    style={{padding: '5px'}}
                    onChange={(e) => handleParticipationStateChange(e.target.value)}
                    defaultValue={participation_state}
                >
                    {Object.keys(selectOptions).map((option, i) =>
                        <option key={i} value={option}>{selectOptions[option]}</option>)
                    }
                </select>
            </div>
        </div>
    );
}
