import {combineReducers} from 'redux'

// States' definition
export interface Authentication {
    isFetching: boolean
    accessToken: string|null
    refreshToken: string|null,
    loginError: string|null
}

export interface State {
    auth: Authentication
}

const initialState = {
    isFetching: false,
    loginError: null,
    accessToken: null,
    refreshToken: null
}

const authReducer = (state: Authentication = initialState, action: any): Authentication => {
    switch (action.type) {
        case 'LOGIN_USER':
            return {...state, accessToken: action.accessToken, refreshToken: action.refreshToken}
        case 'LOGIN_FETCHING':
            return {...state, isFetching: action.isFetching}
        case 'USER_CREDENTIALS_SET':
            return {...state, accessToken: action.access_token, refreshToken: action.refresh_token}
        case 'LOGIN_ERROR':
            return {...state, loginError: action.error}

        default:
            return state
    }
}

export default combineReducers<State>({
    'auth': authReducer
})
