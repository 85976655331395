import {ThunkAction, ThunkDispatch} from 'redux-thunk'
import {AnyAction} from 'redux';
import {postLogin, requestApiEndpoint} from '../../src/services/ApiService';
import history from './history';

export interface UserLogin {
    type: 'LOGIN_FETCHING'
    isFetching: boolean
}

export interface SetUserCredentials {
    type: 'USER_CREDENTIALS_SET',
    access_token: string | null,
    refresh_token: string | null
}

export const fetchUserLogin = (isFetching: boolean): UserLogin => {
    return {
        type: 'LOGIN_FETCHING',
        isFetching
    }
}

export const setUserCredentials = (access_token: string | null, refresh_token: string | null): SetUserCredentials => {
    return {
        type: 'USER_CREDENTIALS_SET',
        access_token,
        refresh_token
    }
}

export const loginError = (error: string | null) => {
    return {
        type: 'LOGIN_ERROR',
        error
    }
}

export const updateStatusForUser = (status: string, personalNumber: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT + "/oauth2/token";
    const requestBody = new FormData();
    requestBody.append('personal_numbers', personalNumber);
    requestBody.append('participation_status', status);

    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any): Promise<void> => {
        const loginData = await requestApiEndpoint(requestBody, '/management/employee/set-participation')
            .then(data => {
                console.log('Status upgedated.')
            })
            .catch(err => {
                console.log('Status konnte nicht upgedated werden.')
            })
    }
}

export const loginUser = (email: string, password: string): ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT + "/oauth2/token";
    const requestBody = new FormData();
    requestBody.append('username', email);
    requestBody.append('password', password);

    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any): Promise<void> => {
        dispatch(loginError(null));

        const loginData = await postLogin(email, password)
            .then(data => {
                dispatch(setUserCredentials(data.access_token, data.refresh_token));
                history.push('/dashboard');
            })
            .catch(err => {
                dispatch(loginError('Invalid login.'));
            })
    }
}

export const logoutUser = () => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: any): Promise<void> => {
        // todo: implement revoke access token endpoint
        dispatch(setUserCredentials(null, null));
        history.push('/login');
    }
}
