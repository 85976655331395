import React from "react";
import {Link} from 'react-router-dom';

interface HeaderComponent {
	title: string,
	pretitle?: string | string[] | null
}

export default function Header({title, pretitle}: HeaderComponent): JSX.Element {
	return (
		<div className="header _content">
			<div className="header-body">
				<div className="header-pretitle">
					{pretitle}
				</div>
				<div className="header-title-wrapper">
					<h1 className="header-title">
						{title}
					</h1>
					<div className="__header-pagination">
						<Link to={'/dashboard'} className="__link">
							zur Übersicht
						</Link>
					</div>
				</div>

			</div>




		</div>
	)
}
